<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as VAT_TYPES } from './store'
import { ROUTES as VAT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import VatTable from './VatTable'
import VatCommonFilters from './VatCommonFilters'
import VatEdit from './VatEdit.vue'

export default {
  name: 'VatList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': VatTable,
    'common-filters': VatCommonFilters,
    'component-edit': VatEdit,
    'component-detail': VatEdit
  },
  data () {
    return {
      VAT_ROUTES,
      title: this.$t('Aliquots vat'),
      rolePerm: ['vat_vat_list'],
      actionEnablePermission: ['vat_vat_enable'],
      actionDisablePermission: ['vat_vat_disable'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 4 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 4 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 4 },
        { key: 'filter__description__icontains', placeholder: this.$t('Description'), type: 'text', col: 4 },
        { key: 'filter__aliquot_perc', placeholder: this.$t('Aliquot percentage'), type: 'number', col: 4 },
        { key: 'filter__deduct_perc', placeholder: this.$t('Deduct percentage'), type: 'number', col: 4 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...VAT_TYPES.GENERIC.vat.vat.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.VAT_ROUTES.VAT_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('vat_vat_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: VAT_TYPES.GENERIC.vat.vat.LIST.MUTATIONS.setSelectedList,
      setSelectAll: VAT_TYPES.GENERIC.vat.vat.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...VAT_TYPES.GENERIC.vat.vat.LIST.ACTIONS
    })
  }
}
</script>
